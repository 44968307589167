import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useInView } from 'react-intersection-observer';
import beacon from '@ui/axios/searchSpring/beacon';
import track from '@ui/axios/searchSpring/track';
import { BagIcon } from '@ui/components/core';
import { ProductCardSlider } from '@ui/components/styles/Card';
import ProductBrandFromTags from './ProductBrandFromTags';
import ProductCardPrice from './ProductCardPrice';
import ProductCardSwatches from './ProductCardSwatches';
import { AnimatedIcon, FilledIcon } from '@ui/components/styles/Icon';
import WishListIcon from '@ui/components/shared/icons/WishList';
import env from '@ui/env';
import AddedToCart from '@ui/components/shared/overlays/AddedToCart/AddedToCart';
import { NO_HOVER_CLASS_NAME } from '@ui/utils/constants';
import { useAuthStore } from '@ui/store/authStore';
import { wishlistSelectors } from '@ui/store/wishlistStoreAsync';
import useCartStore from '@ui/store/cartStore';
import ProductCardImageLink from './ProductCardImageLink';
import useModalStore from '@ui/store/modalStore';
import ProductCardImageCarousel from './ProductCardImageCarousel';
import cn from '@ui/utils/cn';
import { Button } from '@ui/components/core/button';
import { useCartCreation, useCartAdd, useCartUpdate } from '@ui/hooks/useCartMutations';
import { useGetCart } from '@ui/hooks/useCartQuery';
import { isWishlistFrontEnd } from '@ui/helpers/isWishlistFrontEnd';
import { uniq } from 'lodash';
import { useRouter } from 'next/router';
import { useElevarDataLayer } from '@ui/hooks/useElevarDataLayer';
import { getCustomerCountry } from '@ui/hooks/useCustomerCountry';
import useYotpoDataLayer from '@ui/hooks/useYotpoDataLayer';
import SizeSelectorSheet from '../../sheets/SizeSelectorSheet/SizeSelectorSheet';
import { type Product } from '@ui/types/models/Product';
import Spinner from '../../spinner/Spinner';
import { getProductBadge } from '@ui/helpers/productHelpers';
import ProductBadge from '../../badges/ProductBadge/ProductBadge';
import { useRecentlyViewedStore } from '@ui/store.export';

export type ProductCardProducts = {
  ssId?: string;
  brand: string;
  handle: string;
  id: string;
  imageUrl: string;
  images: string[];
  intellisuggestData: string;
  intellisuggestSignature: string;
  msrp: string;
  price: string;
  sku: string;
  ss_id: string;
  ss_sizes: string;
  tags: string[];
  title: string;
  uid: string;
  url: string;
  metafields: string;
  mfield_image_type_image_front?: string;
  variant_inventory_quantity: string;
  variant_price: string;
  variant_sku: string[];
  vendor: string;
  ssVariantSkus?: string[];
  ssUid?: string;
  variant_compare_at_price?: string;
  discountedPrice?: string;
  published_at?: string;
  variant_product_id?: string;
  currency?: string;
  product_type?: string;
  mfield_categorisation_siblings?: string;
};

export type ProductCardProps = {
  ssBeacon?: {
    id: string | undefined;
    tag: string;
    placement: string;
  };
  collection?: string | undefined;
  product: ProductCardProducts;
  href: string;
  onClick?: () => void;
  height: number;
  width: number;
  addViewedProducts?: (product: string) => void;
  layout?: 'collection' | 'product';
  draggable?: boolean;
  metafields?: string;
};

type SearchSpringVariant = {
  available: string;
  inventory: number;
  shopify_variant_id: string;
  sku: string;
  size: string;
  id: string;
};

const ProductCard = ({
  addViewedProducts,
  ssBeacon,
  collection,
  product,
  href,
  onClick,
  height,
  width,
  layout = 'collection',
  draggable,
}: ProductCardProps): React.ReactElement | null => {
  const router = useRouter();
  const { yotpoAddedToCart } = useYotpoDataLayer();
  const { elevarSelectItemEvent, elevarAddToCartEvent } = useElevarDataLayer();
  const { wishlistStore } = wishlistSelectors;
  const customerId = useAuthStore((state) => state.customerId);
  const wishlist = wishlistStore.use.wishlist();
  const addWishlistProduct = wishlistStore.use.addWishlistProduct();
  const removeWishlistProduct = wishlistStore.use.removeWishlistProduct();
  const [wishlistItem, setWishlistItem] = React.useState<boolean>(false);
  const cartID = useCartStore((state) => state?.cartId);
  const cartUpdatedKey = useCartStore((state) => state?.cartUpdatedKey);
  const setCartId = useCartStore((state) => state?.setCartId);
  const setCartUpdatedKey = useCartStore((state) => state?.setCartUpdatedKey);

  const { cartCreateMutation, cartCreateError, cartCreateLoading } = useCartCreation();

  const { cartAddError, cartLinesAddMutation, cartAddLoading } = useCartAdd();

  const { cartLinesUpdateMutation, cartLineUpdateError } = useCartUpdate();
  const productBadgeText = getProductBadge(product.tags);

  React.useEffect(() => {
    void wishlist;
    isWishlistFrontEnd(wishlist) && setWishlistItem(!!wishlist.products[product.handle]);
  }, [product.handle, wishlist]);

  const { data } = useGetCart({
    refreshKey: cartUpdatedKey || '',
    cartId: cartID || '',
  });

  const openModal = useModalStore((state) => state.openModal);
  const openCartDrawer = () => {
    openModal('cart');
  };

  const [wishlistAnimate, setWishlistAnimate] = React.useState<boolean>(false);
  const [showSizes, setShowSizes] = React.useState(false);
  const [fillWishListIcon, setFilledWishListIcon] = React.useState(false);
  const [showAddedToBag, setShowAddedToBag] = React.useState(false);
  const { ref, inView } = useInView({
    threshold: 1,
  });

  React.useEffect(() => {
    if (wishlistItem) {
      setFilledWishListIcon(true);
    } else {
      setFilledWishListIcon(false);
      setWishlistAnimate(false);
    }
  }, [wishlistItem]);

  React.useEffect(() => {
    if (addViewedProducts) {
      addViewedProducts(product.sku);
    }
  }, [inView, product, ssBeacon, addViewedProducts]);

  const addToWishList = (): void => {
    const productId: string = product.uid || (product.ssUid as string);
    const variantId: string = product.id || product.ss_id || (product.ssId as string);
    void addWishlistProduct({
      customerId,
      productId,
      variantId,
    });
    setWishlistAnimate(true);
    setFilledWishListIcon(true);
  };

  const removeFromWishlist = (): void => {
    const productId: string = product.uid || (product.ssUid as string);
    void removeWishlistProduct({
      customerId,
      productId,
    });

    setFilledWishListIcon(false);
    setWishlistAnimate(false);
  };

  const runAddedToBagAnimation = () => {
    setShowAddedToBag(true);

    setTimeout(() => setShowAddedToBag(false), 3000);
  };

  const [addingToCart, setAddingToCart] = React.useState(false);

  const runAddedToCartAnimations = () => {
    setShowSizes(false);
    setAddingToCart(false);
    runAddedToBagAnimation();
    openCartDrawer();
  };

  const addToCart = (variantId: string, ssId: string, variantSku: string): void => {
    setAddingToCart(true);
    yotpoAddedToCart(product.id, 1);
    void elevarAddToCartEvent(
      [
        {
          image: product.imageUrl,
          url: 'products/' + product.handle,
          id: variantSku,
          list: 'collection' as string,
          name: product.title,
          price: product.price.toString(),
          position: 1,
          product_id: product.uid,
          quantity: '1',
          brand: product.vendor ?? 'hello molly',
          category: 'category',
          variant: variantSku.split('-').at(-1) ?? '',
          variant_id: product.uid,
        },
      ],
      router.asPath,
    );
    if (!data?.cart?.cartLines) {
      cartCreateMutation({
        cartCreateInput: {
          buyerIdentity: {
            countryCode: getCustomerCountry(),
          },
          lines: [
            {
              merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
              quantity: 1,
            },
          ],
        },
      }).then((result) => {
        if (cartCreateError || !result) {
          if (cartCreateError) {
            throw new Error(cartCreateError.message);
          }
          throw new Error('Cart not found');
        }
        if (result?.cart && result.cart.cartLines && result.cart.cartLines.length > 0) {
          setCartId?.(result.cart.id);
          setCartUpdatedKey?.();
          runAddedToCartAnimations();
        }
      });
    }

    if (data?.cart?.cartLines) {
      if (data.cart.cartLines.length > 0) {
        if (
          data.cart.cartLines.some(
            (cartLineProduct) => cartLineProduct.variantId === `gid://shopify/ProductVariant/${variantId}`,
          )
        ) {
          const cartUpdateProduct = data.cart.cartLines.find(
            (cartLineProduct) => cartLineProduct.variantId === `gid://shopify/ProductVariant/${variantId}`,
          );

          if (cartUpdateProduct) {
            cartLinesUpdateMutation({
              cartId: cartID ?? '',
              cartUpdateInput: {
                id: cartUpdateProduct.id,
                merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
                quantity: cartUpdateProduct.quantity + 1,
              },
            }).then((result) => {
              if (cartLineUpdateError) {
                throw new Error(cartLineUpdateError.message);
              }

              if (result?.cart && result?.cart.cartLines && result?.cart.cartLines.length > 0) {
                setCartUpdatedKey?.();
                runAddedToCartAnimations();
              }
            });
          }
        } else {
          cartLinesAddMutation({
            cartId: cartID ?? '',
            cartLinesAddInput: [
              {
                merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
                quantity: 1,
              },
            ],
          }).then((result) => {
            if (cartAddError) {
              throw new Error(cartAddError.message);
            }

            if (result?.cart && result?.cart.cartLines && result?.cart.cartLines.length > 0) {
              const cartAddedProduct = result.cart.cartLines.find(
                (cartLineProduct) => cartLineProduct.variantId === `gid://shopify/ProductVariant/${variantId}`,
              );

              if (cartAddedProduct) {
                setCartUpdatedKey?.();
                runAddedToCartAnimations();
              }
            }
          });
        }
      } else {
        cartLinesAddMutation({
          cartId: cartID ?? '',
          cartLinesAddInput: [
            {
              merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
              quantity: 1,
            },
          ],
        }).then((result) => {
          if (cartAddError) {
            throw new Error(cartAddError.message);
          }

          if (result?.cart && result?.cart.cartLines && result?.cart.cartLines.length > 0) {
            setCartUpdatedKey?.();
            runAddedToCartAnimations();
          }
        });
      }
    }
  };

  const card = ProductCardSlider();

  const { themeColor } = FilledIcon({
    filled: fillWishListIcon,
    animate: wishlistAnimate,
  }).classes;

  const { flutter } = AnimatedIcon({ animate: wishlistAnimate }).classes;
  const variantsJson = product.ss_sizes
    ? (JSON.parse(product.ss_sizes.replace(/&quot;/g, '"')) as SearchSpringVariant[])
    : [];

  const isVariantDisabled = ({ id, available }: SearchSpringVariant) => {
    const gqlId = `gid://shopify/ProductVariant/${id}`;

    const cartVariant = data?.cart?.cartLines?.find(({ variantId }) => variantId === gqlId);

    return (
      available !== 'true' ||
      (cartVariant && cartVariant.quantity === cartVariant.quantityAvailable) ||
      cartCreateLoading ||
      cartCreateLoading ||
      cartAddLoading
    );
  };

  async function handleImageClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    await elevarSelectItemEvent([
      {
        brand: 'brand',
        category: 'category',
        compare_at_price: product.variant_compare_at_price ?? '',
        id: product.uid,
        list: collection ?? '',
        name: product.title,
        price: product.price.toString(),
        product_id: product.uid,
        quantity: null,
        variant: product.variant_sku?.[0],
        variant_id: product.variant_product_id ?? product.uid,
        position: 1,
      },
    ]);

    if (showSizes) {
      event.preventDefault();
      setShowSizes(false);
      return;
    }
    useRecentlyViewedStore.setState({
      ssViewedProduct: {
        id: product.id,
        uid: product.uid,
        sku: product.sku,
        intellisuggestData: product.intellisuggestData,
        intellisuggestSignature: product.intellisuggestSignature,
        variantSkus: product.variant_sku,
        ss_sizes: product.ss_sizes,
        published_at: product.published_at,
      },
    });

    if (ssBeacon?.placement === 'home-page' || ssBeacon?.placement === 'other') {
      await beacon({
        type: 'profile.click',
        id: uuidv4(),
        pid: ssBeacon.id,
        tag: ssBeacon.tag,
        placement: ssBeacon.placement,
        productSku: product.sku,
      });

      await beacon({
        type: 'profile.product.click',
        id: uuidv4(),
        pid: ssBeacon.id,
        tag: ssBeacon.tag,
        placement: ssBeacon.placement,
        productSku: product.sku,
      });
    } else if (ssBeacon?.placement === 'product-page') {
      await beacon({
        type: 'profile.click',
        id: uuidv4(),
        pid: ssBeacon.id,
        tag: ssBeacon.tag,
        placement: ssBeacon.placement,
        productSku: product.sku,
        seed: [{ sku: product.sku }],
      });

      await beacon({
        type: 'profile.product.click',
        id: uuidv4(),
        pid: ssBeacon.id,
        tag: ssBeacon.tag,
        placement: ssBeacon.placement,
        productSku: product.sku,
        seed: [{ sku: product.sku }],
      });
    }

    if (product.intellisuggestData && product.intellisuggestSignature) {
      await track({
        data: product.intellisuggestData,
        signature: product.intellisuggestSignature,
        url: collection ? `/collections/${collection}/products/${product.handle}` : `/products/${product.handle}`,
        referrer: env.NEXT_PUBLIC_BASE_URL,
      });
    }

    if (onClick) {
      onClick();
    }
  }

  const indexOfFrontImage = Number(product.mfield_image_type_image_front ?? 1) - 1;

  const sortedProductImages = useMemo(() => {
    return product.images?.length > 1
      ? uniq([product.images[indexOfFrontImage], ...product.images].filter(Boolean))
      : [];
  }, [indexOfFrontImage, product.images]);

  const productModel: Product = {
    id: product.id,
    title: product.title,
    productType: product.product_type || '',
    tags: product.tags,
    variants: variantsJson.map((variant) => ({
      id: String(variant.shopify_variant_id),
      size: variant.size,
      sku: variant.sku,
      quantityAvailable: String(variant.inventory),
      availableForSale: variant.available === 'true',
    })),
  };
  return (
    <div ref={ref} className="isolate relative group">
      <div className={`${card.classes.slideWrapper} ${showAddedToBag ? NO_HOVER_CLASS_NAME : ''}`}>
        {env.FEATURE_PRODUCT_CARD_QUICK_ADD && !showAddedToBag && (
          <Button
            className="z-[2] size-7 no-underline p-0 absolute bottom-2 right-2 md:hidden"
            onClick={() => setShowSizes(true)}
            variant="unstyled"
          >
            {addingToCart ? <Spinner className="size-5 border-2" /> : <BagIcon className="size-7" />}
          </Button>
        )}
        {env.FEATURE_PRODUCT_CARD_CAROUSEL && sortedProductImages.length > 1 ? (
          <>
            <ProductCardImageCarousel
              className={cn(layout === 'product' && 'hidden md:block')}
              imageHeight={height}
              imageWidth={width}
              onImageClick={handleImageClick}
              productHandle={product.handle}
              productImages={sortedProductImages.slice(0, 6)}
              productUrl={href}
              draggable={draggable}
            />
            {layout === 'product' && (
              <ProductCardImageLink
                className="md:hidden"
                href={href}
                src={product.imageUrl}
                alt={`${product.handle}-product-image`}
                height={height}
                width={width}
                onClick={handleImageClick}
                productHandle={product.handle}
              />
            )}
          </>
        ) : (
          <ProductCardImageLink
            href={href}
            src={product.imageUrl}
            alt={`${product.handle}-product-image`}
            height={height}
            width={width}
            onClick={handleImageClick}
            productHandle={product.handle}
          />
        )}

        <AddedToCart show={showAddedToBag} className="h-10 bottom-0 text-xs z-10 rounded-none" />

        {product.ss_sizes && (
          <div className={`${card.classes.slideContent} ${showSizes ? 'opacity-100 h-auto' : ''} max-sm:hidden`}>
            <div className="flex flex-wrap w-full px-2 py-4 justify-center gap-2 md:gap-4">
              {variantsJson.map((variant) => (
                <Button
                  variant="outline"
                  key={`${product.handle}-${variant.size}-button`}
                  disabled={isVariantDisabled(variant)}
                  className={cn('size-8 bg-transparent !text-[9px] p-0', variant.size === 'One Size' && 'w-auto px-2')}
                  onClick={() => {
                    if (product.ss_id) {
                      addToCart(variant.shopify_variant_id, product.ss_id, variant.sku);
                    } else {
                      addToCart(variant.shopify_variant_id, product.id, variant.sku);
                    }
                  }}
                >
                  {variant.size}
                </Button>
              ))}
            </div>
          </div>
        )}

        <SizeSelectorSheet
          layout="flex"
          product={productModel}
          open={showSizes}
          onClose={() => setShowSizes(false)}
          onSelect={(variant) => {
            if (product.ss_id) {
              addToCart(variant.id, product.ss_id, variant.sku);
            } else {
              addToCart(variant.id, product.id, variant.sku);
            }
          }}
        />
      </div>
      <div className="mt-2.5 md:mt-2 block space-y-2 md:space-y-1">
        <div className="space-y-1">
          <div className="text-[0.75rem]/[1rem] md:text-[0.6875rem]/[1.375rem] line-clamp-2 font-bold uppercase">
            {product.title}
          </div>
          <div className="flex flex-col gap-1 items-start md:flex-row">
            <ProductCardPrice
              price={product.price}
              compareAtPrice={product.variant_compare_at_price}
              currency={product.currency}
            />
            {productBadgeText && <ProductBadge text={productBadgeText} />}
          </div>
        </div>
        <div className="space-y-1">
          <ProductCardSwatches currentHandle={product.handle} swatches={product.mfield_categorisation_siblings} />
          <ProductBrandFromTags tags={product.tags} />
        </div>
      </div>
      <Button
        variant="unstyled"
        className="absolute w-7 h-7 top-[0.375rem] right-[0.375rem] md:top-[0.4rem] md:right-[0.4rem] z-[2] overflow-visible"
        onClick={() => {
          if (wishlistItem) {
            removeFromWishlist();
          } else {
            addToWishList();
          }
        }}
      >
        <WishListIcon height="10" width="10" viewBox="0 0 25 25" className={flutter} />
        <WishListIcon height="14" width="14" viewBox="0 0 25 25" className={flutter} />
        <WishListIcon height="12" width="12" viewBox="0 0 25 25" className={flutter} />
        <WishListIcon height="16" width="16" viewBox="0 0 25 25" className={flutter} />
        <WishListIcon
          className={wishlistAnimate || wishlistItem ? themeColor : 'fill-transparent md:hover:fill-[#F7D6E3]'}
        />
      </Button>
    </div>
  );
};

ProductCard.defaultProps = {
  addViewedProducts: undefined,
  ssBeacon: undefined,
  collection: undefined,
  onClick: undefined,
};

export default React.memo(ProductCard);
